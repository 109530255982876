/* eslint-disable */

// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';


import '../routes/ajax';

var xhr_load = null;
var xhr = null;
var bool_factureTrouvee = false;

function valeurOk(){
    var ok = true;

    if($('#num_facture').val() != '') {
        $('.error', '.champ.facture').hide();
        $('#no-facture').text($('#num_facture').val());
    } else {
        $('.error', '.champ.facture').show();
        ok = false;
    }

    if($('#date_transport').val() != '') {
        $('.error', '.champ.date_transport').hide();
        $('#date-transport').text($('#date_transport').val());
    } else {
        $('.error', '.champ.date_transport').show();
        ok = false;
    }

    if($('#date_facture').val() != '') {
        $('.error', '.champ.date_facture').hide();
        $('#date_facture-transport').text($('#date_facture').val());
    } else {
        $('.error', '.champ.date_facture').show();
        ok = false;
    }
    //
    // if($('#montant').val() != '') {
    //     $('.error', '.champ.montant').hide();
    //     $('#montant-payer').text($('#total').val());
    // } else {
    //     $('.error', '.champ.montant').show();
    //     ok = false;
    // }

    return ok;
}
function valeurOk2()
{
    var ok = true;

    if($('#credit_card').val() != '') {
        $('.error', '.champ.carte_credit').hide();
        $('#cc-ending').text($('#credit_card').val().substr(-4));
    } else {
        $('.error', '.champ.carte_credit').show();
        ok = false;
    }

    if($('#date_expiration').val() != '') {
        $('.error', '.champ.date_expiration').hide();
    } else {
        $('.error', '.champ.date_expiration').show();
        ok = false;
    }

    if($('#cvc').val() != '') {
        $('.error', '.champ.cvc').hide();
    } else {
        $('.error', '.champ.cvc').show();
        ok = false;
    }

    if($('#prenom').val() != '' && $('#nom').val() != '') {
        $('.error', '.nom_complet').hide();
    } else {
        $('.error', '.nom_complet').css({'display':'block'});

        ok = false;
    }



    if (!$('#allow_email').prop( "checked" )) {
        $('.error_email', '.email_row').hide();
    } else {
        var found_error = false;
        var error_message = '';

        if ($('#email_confirmation').val() == '' || $('#email_confirmation2').val() == '') {

            found_error = true;
            error_message = 'Votre courriel est requis.';
        } else {

            if (!isValidEmailAddress($('#email_confirmation').val()) || !isValidEmailAddress($('#email_confirmation2').val())) {
                found_error = true;
                error_message = "Votre courriel est invalide";
            } else {
                if ($('#email_confirmation').val() != $('#email_confirmation2').val()) {
                    found_error = true;
                    error_message = "Votre courriel de confirmation n'est pas identique à votre courriel.";
                } else {
                    found_error = false;
                    error_message = '';
                }
            }


        }

        if (found_error) {
            if (error_message != '') {
                $('.error_email', '.email_row').html(error_message);
            }
            $('.error_email', '.email_row').css({'display':'block'});
            ok = false;
        } else {
            $('.error_email', '.email_row').hide();

        }


    }

    return ok;
}
function valeurOk3(){
  var ok = true;

  if($('#cbx_termes_conditions').is(':checked')) {
      $('.error', '.champ.termes_conditions').hide();
  } else {
      $('.error', '.champ.termes_conditions').show();
      ok = false;
  }

  return ok;
}

function paypal(callback){
  var montant = $('#total').val();
  montant = montant.replace(/[^0-9\.,]/g, '');
  montant = montant.replace(',', '.');

  if( xhr_load != null ) {
      xhr_load.abort();
      xhr_load = null;
  }

  var data_paypal = {
    action : 'paypal',
    date_transport : $('#date_transport').val(),
    date_facture : $('#date_facture').val(),
    num_facture : $('#num_facture').val(),
    montant : montant,
    card : $('#credit_card').val(),
    exp : $('#date_expiration').val(),
    cvc : $('#cvc').val(),
    prenom : $('#prenom').val(),
    nom : $('#nom').val(),
    conf_email : $('#email_confirmation').val()
  };
  $.post( afp_vars.afp_ajax_url, data_paypal, function(data) {
    if( data ) {
      var msg = '';
      if(data.RESULT == 0) {
        $('.paypal_confirm_id').text(data.PNREF);
        goToPage4(data);
      } else {
        switch(parseInt(data.RESULT)) {
          case 23:
            $('.paypal_error').hide();
            $('.etape2 .paypal_error').text("Une erreur est survenue avec le paiement (Numéro de carte invalide)");
            $('.paypal_error').show();
            goToPage2();
            break;
          case 24:
            msg = '';
            $('.paypal_error').hide();
            $('.etape2 .paypal_error').text("Une erreur est survenue avec le paiement (Date d'expiration invalide)");
            $('.paypal_error').show();
            goToPage2();
            break;
          default:
            msg = data.RESPMSG;
            $('.paypal_error').hide();
            $('.etape3 .paypal_error').text("Une erreur est survenue avec le paiement (" + data.RESPMSG + ")");
            $('.paypal_error').show();


            goToPage3();
            break;
        }
      }
      callback(data);
    }else{
      callback(false);
    };
  }, "json");

  // xhr_load = $.ajax({
  //     type : 'POST',
  //     url : window.admin_url,
  //     dataType : 'json',
  //     async : false,
  //     data : {
  //       action : 'paypal',
  //       date_transport : $('#date_transport').val(),
  //       date_facture : $('#date_facture').val(),
  //       num_facture : $('#num_facture').val(),
  //       montant : montant,
  //       card : $('#credit_card').val(),
  //       exp : $('#date_expiration').val(),
  //       cvc : $('#cvc').val(),
  //       prenom : $('#prenom').val(),
  //       nom : $('#nom').val(),
  //       conf_email : $('#email_confirmation').val()
  //     },
  //     success : function(data) {
  //       if(data.RESULT == 0) {
  //         $('.paypal_confirm_id').text(data.PNREF);
  //         goToPage4(data);
  //       } else {
  //         switch(parseInt(data.RESULT)) {
  //           case 23:
  //             $('.paypal_error').hide();
  //             $('.etape2 .paypal_error').text("Une erreur est survenue avec le paiement (Numéro de carte invalide)");
  //             $('.paypal_error').show();
  //             goToPage2();
  //             break;
  //           case 24:
  //             msg = '';
  //             $('.paypal_error').hide();
  //             $('.etape2 .paypal_error').text("Une erreur est survenue avec le paiement (Date d'expiration invalide)");
  //             $('.paypal_error').show();
  //             goToPage2();
  //             break;
  //           default:
  //             msg = data.RESPMSG;
  //             $('.paypal_error').hide();
  //             $('.etape3 .paypal_error').text("Une erreur est survenue avec le paiement (" + data.RESPMSG + ")");
  //             $('.paypal_error').show();


  //             goToPage3();
  //             break;
  //         }
  //       }
  //     }
  // });
}

function isValidEmailAddress(emailAddress)
{
  var pattern = new RegExp(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/);
  return pattern.test(emailAddress);
}

function recaptcha()
{
  var ok = false;

  if($('#test_email').val() == '') {
      ok = true;
  }
  //
  // if(ok) {
  //     $('.error_capcha').hide();
  // } else {
  //     $('.error_capcha').show();
  // }

  return ok;
}

function factureTrouvee(callback)
{
  var aFacture = false;

  if( xhr != null ) {
      xhr.abort();
      xhr = null;
  }

  var data_facture = {
    action : 'trouver_facture',
    date_transport : $('#date_transport').val(),
    date_facture : $('#date_facture').val(),
    num_facture : $('#num_facture').val()
  };

  $.post( afp_vars.afp_ajax_url, data_facture, function(data) {
    if( data ) {
      $('#form_page1').append( data );

      if (data.message != '') {
        $('.error_globale').html(data.message);
      }

      if (data.status = 'success') {
        $('.error_globale').hide();

        if (data.etat != 'payed') {
          if (data.solde) {
            aFacture = true;
            $('.field_montant').val(data.solde);
            $('.label_montant').html(data.solde + '$');

            $('.field_interet').val(data.interet);
            $('.label_interet').html(data.interet + '$');


            $('.field_total').val(data.total);
            $('.label_total').html(data.total + '$');

            $('#montant-payer').text(data.total  + '$');
          }
        } else {
          $('.error_globale').show();
          aFacture = false;
        }
        callback(aFacture);

      } else {
        $('.error_globale').show();
        aFacture = false;
        callback(aFacture);
      }
    };
  }, "json");

  return aFacture;
}

function factureTrouveeSondage()
{
  var aFacture = false;
  $('#sondage_content').removeClass('sondage_hidden');
  $('#form_content').hide();

  // $.ajax({
  //     type : 'GET',
  //     url : window.admin_url,
  //     async : false,
  //     dataType : 'json',
  //     data : {
  //         action : 'trouver_facture_sondage',
  //         date_transport : $('#date_transport').val(),
  //         date_naissance : $('#date_naissance').val()
  //     },
  //     success : function(data) {
  //         if (data.status == 'success') {
  //             $('.error_globale').hide();
  //
  //             aFacture = true;
  //         } else {
  //             aFacture = false;
  //         }
  //
  //         if (aFacture) {
  //
  //             $('#form_content').hide();
  //             $('#sondage_content').removeClass('sondage_hidden');
  //         } else {
  //             $('.error_globale').show();
  //         }
  //
  //
  //     }
  // });


}

function goToPage1() {

  $('.paiement_step').removeClass('active');
  $('.paiement_step.step1').addClass('active');
  $('.etape1').show();
  $('.etape2').hide();
  $('.etape3').hide();
  $('.paiement_top .breadcrumb_list .steps').removeClass('active');
  $('.paiement_top .breadcrumb_list .list_step_1').addClass('active');

  if (!$('.paiement_top  .form_breadcrumb').is(":visible")) {

      $('html,body').animate({
          scrollTop: $(".step1 .breadcrumb_list .list_step_1").offset().top - 90
      }, 600);
  }
}

function goToPage2() {
  $('.paiement_step').removeClass('active');
  $('.paiement_step.step2').addClass('active');
  $('.etape1').hide();
  $('.etape2').show();
  $('.etape3').hide();
  $('.paiement_top .breadcrumb_list .steps').removeClass('active');
  $('.paiement_top .breadcrumb_list .list_step_2').addClass('active');


  if (!$('.paiement_top .form_breadcrumb').is(":visible")) {
      $('html,body').animate({
          scrollTop: $(".step2 .breadcrumb_list .list_step_2").offset().top - 100
      }, 600);

  }

}

function goToPage3() {
  $('.paiement_step').removeClass('active');
  $('.paiement_step.step3').addClass('active');
  $('.etape1').hide();
  $('.etape2').hide();
  $('.etape3').show();
  $('.paiement_top .breadcrumb_list .steps').removeClass('active');
  $('.paiement_top .breadcrumb_list .list_step_3').addClass('active');

  if (!$('.paiement_top .form_breadcrumb').is(":visible")) {
      $('html,body').animate({
          scrollTop: $(".step3 .breadcrumb_list .list_step_3").offset().top - 100
      }, 600);
  }

}

function goToPage4(data) {
  var redirect = data.redirect;
  var url = redirect + '?token=' + data.token;

  // console.log(data);

  if (data.email_confirmation && data.email_confirmation.status !== undefined) {
    url += '&email_status=' + data.email_confirmation.status;
  }

  if (data.email_confirmation && data.email_confirmation.message !== undefined) {
    url += '&email_message=' + data.email_confirmation.message;
  }

  window.location = url;
}

$('#form_sondage').submit(function() {
  $('#form_sondage .error').hide();
  $('.vy_paiement').addClass('vy_ajax_loading');
  // showModal('Traitement en cours...', 'Veuillez patienter un instant s.v.p.', true);

  setTimeout(function () {

      factureTrouveeSondage();



      $('.vy_paiement').removeClass('vy_ajax_loading');
          //hideModal();

  }, 0);

  return false;
});

function valeurOkConfirm()
{
  var ok = true;


  var found_error = false;
  var error_message = '';

  if ($('#email').val() == '' || $('#confirm_email').val() == '') {

      found_error = true;
      error_message = 'Votre courriel est requis.';
  } else {

      if (!isValidEmailAddress($('#email').val()) || !isValidEmailAddress($('#confirm_email').val())) {
          found_error = true;
          error_message = "Votre courriel est invalide";
      } else {
          if ($('#email').val() != $('#confirm_email').val()) {
              found_error = true;
              error_message = "Votre courriel de confirmation n'est pas identique à votre courriel.";
          } else {
              found_error = false;
              error_message = '';
          }
      }

  }

  if (found_error) {
      if (error_message != '') {
          $('.error_email', '#form_confirmation').html(error_message);
      }
      $('.error_email', '#form_confirmation').css({'display':'block'});
      ok = false;
  } else {
      $('.error_email', '#form_confirmation').hide();

  }


  return ok;
}
$(function(){
  $(document).on('click', '#allow_email', function() {
    if ($(this).prop( "checked" )) {
        $('div.field_email').slideDown();
    } else {
        $('div.field_email').slideUp();
        $('#email_confirmation').val('');
    }
  });

  $('.argent_format').inputmask({
    alias:"numeric",
    radixPoint: ",",
    groupSeparator:"",
    autoGroup:true,
    digits:2,
    digitsOptionnal:false,
    suffix:' $',
    rightAlign : false,
  });

  $('.pictogramme').inputmask('999');
  $('.datepicker').inputmask('9999-99-99');

  if (!$('.page-template .paiement_step .form_breadcrumb').is(":visible")) {
    // Style on desktop

    $('.exp').inputmask('99/99');
    $('.credit_card').inputmask('9999 9999 9999 9999');

    $('.datepicker_birth').datepicker({
        dateFormat: "yy-mm-dd",
        changeYear: true,
        changeMonth: true,
        defaultDate: '1970-06-01',
        yearRange: '1920:' + new Date().getFullYear()
    });

    $('.datepicker').datepicker({
        dateFormat: "yy-mm-dd",
        changeYear: true,
        changeMonth: true
    });

  } else {
      // Style to add when on mobile
      $('#credit_card').attr('type', 'tel');
      $('#date_expiration').attr('type', 'tel');
      $('.exp').inputmask('99/99');
      $('.credit_card').inputmask('9999999999999999');

      $('.datepicker').attr('type', 'tel');
  }

  $('#form_page1').submit(function() {
    $('.error').hide();
    $('.vy_paiement').addClass('vy_ajax_loading');
    // showModal('Traitement en cours...', 'Veuillez patienter un instant s.v.p.', true);
    factureTrouvee(function(response) {
      console.log(response);
      bool_factureTrouvee = response;

      var bool_valeurOk = valeurOk();
      var bool_recaptcha = recaptcha();
      console.log(valeurOk());
      console.log('bool_factureTrouvee'+bool_factureTrouvee);
      console.log(recaptcha());
      if(bool_valeurOk && bool_factureTrouvee && bool_recaptcha) {
          $('.vy_paiement').removeClass('vy_ajax_loading');
          //hideModal();
          goToPage2();
      } else {
          if (!bool_factureTrouvee) {
              if ($('.error_globale').html() == '') {
                  $('.error_globale').html('Erreur, impossible de trouver votre facture.');
              }
              $('.error_globale').show();
          }

          $('.vy_paiement').removeClass('vy_ajax_loading');
          //hideModal();
      }
    });

    return false;
  });

$('#form_page2').submit(function() {
  $('.vy_paiement').addClass('vy_ajax_loading');
    // showModal('Traitement en cours...', 'Veuillez patienter un instant s.v.p.', true);

    //Grosse patch degeux sinon le preloader s'affiche seulement quand tout est terminé
    //La methode show() de JQuery est reféfinie pour intégrer une animation qui pousse l'éxécution
    //à la fin
    setTimeout(function () {
        if(valeurOk2()) {
            goToPage3();
        }
        $('.vy_paiement').removeClass('vy_ajax_loading');
            //hideModal();
    }, 0);

    return false;
});

$('#form_page3').submit(function() {
  $('.vy_paiement').addClass('vy_ajax_loading');
    // showModal('Traitement en cours...', 'Veuillez patienter un instant s.v.p.', true);

    setTimeout(function () {
        if(valeurOk3()) {
          console.log('valeurOk3 + paypal');
          // paypal();
          paypal(function(response) {
            console.log(response);
            $('.vy_paiement').removeClass('vy_ajax_loading');
          });
        }else{
          $('.vy_paiement').removeClass('vy_ajax_loading');
        }
            //hideModal();
    }, 0);

    return false;
});



// $(document).on("cut copy paste","input[type=email]",function(e) {
//     e.preventDefault();
// });



$('#form_confirmation').submit(function(e) {
  console.log('submit 2');
  e.preventDefault();
    $('#confirmation_error').removeClass('error').removeClass('success');
    $('#conf_content_error').hide();

    if (valeurOkConfirm()) {
      $('.vy_paiement').addClass('vy_ajax_loading');
        // showModal('Traitement en cours...', 'Veuillez patienter un instant s.v.p.', true);
        var data_confirmation = {
          action : 'send_confirmation',
          email : $('#email').val(),
          token : $('#token').val()
        };
        $.post( afp_vars.afp_ajax_url, data_confirmation, function(data) {
          if( data ) {
            if (data.message) {
              $('#confirmation_error').html(data.message);
            }

            if (data.status == 'success') {
              $('#confirmation_error').addClass('success');
              $('#conf_content_error').show();
              $('#email').val('');
            } else {
              $('#confirmation_error').addClass('error');
              ('#conf_content_error').show();
            }

            $('.vy_paiement').removeClass('vy_ajax_loading');
          };
        }, "json");

        // setTimeout(function () {

        //     if( xhr != null ) {
        //         xhr.abort();
        //         xhr = null;
        //     }
        //     xhr = $.ajax({
        //         type : 'post',
        //         url : window.admin_url,
        //         async : false,
        //         dataType : 'json',
        //         data : {
        //             action : 'send_confirmation',
        //             email : $('#email').val(),
        //             token : $('#token').val()
        //         },
        //         success : function(data) {

        //             if (data.message) {
        //                 $('#confirmation_error').html(data.message);
        //             }

        //             if (data.status == 'success') {
        //                 $('#confirmation_error').addClass('success');
        //                 $('#conf_content_error').show();
        //                 $('#email').val('');
        //             } else {
        //                 $('#confirmation_error').addClass('error');
        //                 ('#conf_content_error').show();
        //             }

        //             $('.vy_paiement').removeClass('vy_ajax_loading');
        //     //hideModal();
        //         }
        //     });



        // }, 0);
    }

    return false;
});

});
